import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Flex,
  Button,
  Text,
  Box,
  Alert,
  AlertIcon,
  AlertDescription,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";

const verificationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email format").optional(),
  phone: Yup.string()
    .nullable()
    .matches(/^\+\d{10,15}$/, "Invalid phone format. Use +<country_code><number>")
    .optional(),
  code: Yup.string()
    .required("Please enter the verification code.")
    .matches(/^\d{6}$/, "Verification code must be 6 digits"),
});

const VerifySMS: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [resendMessage, setResendMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // Get email and phone from query params
  const userEmail = searchParams.get("email") || "";
  const userPhone = searchParams.get("phone") || "";

  const initialFormValues = {
    email: userEmail,
    phone: userPhone,
    code: "",
  };

  interface FormValues {
    email: string;
    phone: string;
    code: string;
  }

  interface FormikHelpers {
    setSubmitting: (isSubmitting: boolean) => void;
  }

  const handleResendCode = async (values: FormValues) => {
    setIsResendLoading(true);
    setResendMessage("");
    setErrorMessage("");

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_HOST}/auth/resend-sms`, values);
      if (response.data.status === "success") {
        setResendMessage("Verification code resent successfully!");
      } else {
        setErrorMessage(response.data.message || "Failed to resend verification code.");
      }
    } catch (error) {
      setErrorMessage("Error resending verification code.");
    }

    setIsResendLoading(false);
  };

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers) => {
    setIsLoading(true);
    setErrorMessage("");

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_HOST}/auth/verify-sms`, values);

      if (response.data.status === "success") {
        setIsSuccess(true);
      } else {
        setErrorMessage(response.data.message || "Invalid code. Please try again.");
      }
    } catch (error) {
      setErrorMessage("Failed to verify the code. Please try again.");
    }
    setIsLoading(false);
    setSubmitting(false);
  };

  return (
    <Flex direction="column" w="full" maxW="600px" gap="25px" alignItems="center" justifyContent="center">
      {isSuccess ? (
        <Box textAlign="center">
          <Text fontSize="lg" fontWeight="bold">Verification successful! You can now log in.</Text>
          <Button mt={4} onClick={() => navigate("/login")}>Go to Login</Button>
        </Box>
      ) : (
        <>
          <Text fontSize="2xl" fontWeight="bold">Verify Your Account</Text>
          <Formik
            initialValues={initialFormValues}
            validationSchema={verificationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values }) => (
              <Form style={{ width: "100%" }}>
                <Flex direction="column" gap="25px">
                  <Field name="email">
                    {({ field, form }: any) => (
                      <FormControl isInvalid={form.errors.email && form.touched.email}>
                        <FormLabel>Email Address</FormLabel>
                        <Input {...field} placeholder="Enter your email" />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="phone">
                    {({ field, form }: any) => (
                      <FormControl isInvalid={form.errors.phone && form.touched.phone}>
                        <FormLabel>Phone Number</FormLabel>
                        <Input {...field} placeholder="Enter your phone number" />
                        <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="code">
                    {({ field, form }: any) => (
                      <FormControl isInvalid={form.errors.code && form.touched.code}>
                        <FormLabel>Verification Code</FormLabel>
                        <Input {...field} placeholder="Enter 6-digit code" />
                        <FormErrorMessage>{form.errors.code}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Flex>

                {errorMessage && (
                  <Alert status="error" mt={4} rounded="8px">
                    <AlertIcon />
                    <AlertDescription>{errorMessage}</AlertDescription>
                  </Alert>
                )}

                {resendMessage && (
                  <Alert status="success" mt={4} rounded="8px">
                    <AlertIcon />
                    <AlertDescription>{resendMessage}</AlertDescription>
                  </Alert>
                )}

                <Flex justifyContent="space-between" mt={4}>
                  <Button onClick={() => handleResendCode(values)} isLoading={isResendLoading} variant="outline">
                    Resend Code
                  </Button>
                  <Button type="submit" isLoading={isSubmitting || isLoading} colorScheme="blue">
                    Verify
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Flex>
  );
};

export default VerifySMS;
